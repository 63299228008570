<template>
  <div class="wrapper">
    <HeroRedirect :data="heroProps" />
  </div>
</template>

<script>
import HeroRedirect from "@/components/Heroes/HeroRedirect.vue";
import { mapGetters } from "vuex";
import { manageSEO } from "@/services/dataManagement.js";

export default {
  name: "RedirOk",
  metaInfo() {
    return manageSEO(this.seo, this.seo.general, false, this.dealerName);
  },
  components: {
    HeroRedirect,
  },
  data() {
    return {
      dealerName: this.$store.state.dealerInfo.dealerName,
      redirData: {
        heroTitle: "FORMULÁRIO NÃO SUBMETIDO",
        heroText:
          "Pedimos desculpa, mas o seu formulário não foi submetido com sucesso.",
      },
    };
  },
  computed: {
    ...mapGetters({
      getProp: "form/getProp",
    }),
    heroProps() {
      const obj = this.getProp("formError");
      return Object.keys(obj).length !== 0 ? obj : this.redirData;
    },
    seo() {
      return this.$store.state.homepage.seo;
    },
  },
};
</script>
